import React from 'react';
import Component from "@reactions/component";
import { Pane, Dialog, Spinner ,toaster} from 'evergreen-ui';
import Prints from '@material-ui/icons/Print';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
// import * as moment from "moment";
import MaterialDatatable from "material-datatable";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from "universal-cookie";
import host from '../host';
import 'react-toastify/dist/ReactToastify.css';
const cookies = new Cookies();

const columns = [
  { field: "print", name: "طباعه ", options: { width: 100, filter: true, sort: true, } },
  { field: "date", name: "تاريخ ", options: { width: 200, filter: true, sort: true, } },
  { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
  { field: "id", name: "الرقم", options: { filter: true, sort: true, } },
];
const options = {
  selectableRows: false,
  responsive: 'scroll',
  rowCursorHand: false,
  sort: false,
  print: false,
  filter: false,
  download: false,
  viewColumns:false,
  textLabels: {
    body: {
      noMatch: " لم يتم العثور على سجلات مطابقة",
      toolTip: "فرز",
    },
    pagination: {
      next: "الصفحة التالية",
      previous: "الصفحة السابقة",
      rowsPerPage: "عدد الصفوف",
      displayRows: "من",
    },
    toolbar: {
      search: "بحث",
      downloadCsv: "تنزيل",
      print: "Print",
      viewColumns: " التحكم بالاعمدة",
      filterTable: "فلتر",
    },
    filter: {
      all: "الكل",
      title: "فلتر",
      reset: "إعادة تعيين",
    },
    viewColumns: {
      title: "عرض الأعمدة",
      titleAria: "إظهار / إخفاء أعمدة الجدول",
    },

  }
}
class Table2 extends React.Component {
  constructor() {
    super();
    this.state = {
      Done: [],
      spin: true,
      note: ''
    }
  }
  componentDidMount() {
    axios.get(host + `api/v1/Store/`, { headers: {} })
      .then(res => {
        var Data=res.data
        Data.reverse()
        let arr = [];
        for (let i = 0; i < Data.length; i++) {
          let obj = {
            id: i+1,
            name: Data[i].user.name,
            date: Data[i].uptime,
            print:
              <Component initialState={{ isShown: false, data: [], note: '', spin1: true ,Name:'',uptime:''}}>
                {({ state, setState }) => (
                  <Pane>
                    <Dialog
                      isShown={state.isShown}
                      title="طباعة"
                      onCloseComplete={() => setState({ isShown: false })}
                      confirmLabel="طباعة"
                      cancelLabel="الغاء"
                      onConfirm={() => {
                        window.print();
                        this.chengeOrderToDone(Data[i]._id)
                        // window.print();
                        setState({ isShown: false })
                      }}
                    >
                      <PrintProvider>

                        <NoPrint>
                          {!state.spin1 ? (
                            <Print single name="foo">

                              {state.data.map((dnone, i) => (
                                <div key={i} id='tablePrintMargin' >
                                  <div id='RootNAmeprint'>
                                  <div> {state.uptime}</div>
                                    <div> {state.Name}</div>
                                  </div>
                                  <div id='SectionTable'>{dnone.sections_name}</div>
                                  <table id='students' key={dnone._id}>
                                    <tr>
                                      <th>الرقم</th>
                                      <th>الصنف</th>
                                      <th>العدد/الوزن</th>
                                    </tr>
                                    <tbody >
                                      {dnone.items.map((item, ii) => (
                                        <tr key={ii}>
                                          <td style={{ width: 60 }} >{ii + 1}</td>
                                          <td  >{item.name}</td>
                                          <td style={{ width: 100 }} >{item.count + ' ' + item.type}</td>
                                        </tr>

                                      ))}
                                    </tbody>

                                  </table>
                                </div>
                              ))}


                              {/* <CustomizedTable item={this.state.data} order={orderDone.user.name} date={orderDone.uptime} /> */}
                              <div id='notsAllOrder'>الملاحضات:{state.note}</div>
                            </Print>
                          ) : (
                            <div style={{ width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Spinner />
                            </div>)}
                        </NoPrint>
                      </PrintProvider>
                    </Dialog>
                    <Prints
                      onClick={() => {

                        // ctx.action.HandlePrintDone(order.items)
                        // ctx.action.GetOrderId(order._id)

                        axios.get(host + `api/v1/Store/order/` + Data[i]._id, { headers: { "Content-Type": "application/json", token: cookies.get("token") } })
                          .then(response => {
                            setState({
                              Name:Data[i].user.name,
                              uptime:Data[i].uptime,
                              data: response.data.order[0].sections,
                              note: response.data.order[0].sections[0].note,
                              spin1: false
                            })
                            console.log();

                          })
                          .catch((error) => { console.log('error ' + error) })
                        // console.log(order._id)

                        setState({ isShown: true })
                      }} style={{ color: '#ffc107', cursor: 'pointer' }} />

                  </Pane>
                )}
              </Component>
            ,
          };
          arr.push(obj);
        }
        this.setState({
          Done: arr, spin: false
        });
      })
      .catch((error) => { console.log('error ' + error) })


  }
  chengeOrderToDone(id) {
    var headers = { "Content-Type": "application/json", token: cookies.get("token") };

    axios({ url: host + "api/v1/Store/OrderDone/" + id, method: "POST", headers: headers })
      .then(response => {
        if (response.status === 200) {
          this.componentDidMount();
          toast('تم انجاز الطلب', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
      })
      .catch(function (error) { if (error.request.response) { toaster.danger(error.request.response); } });
  }
  getMuiTheme = () => createTheme({
    overrides: {
      MaterialDatatableBodyCell: { root: { textAlign: 'right', } },
      MuiTableCell: { root: { textAlign: 'right' } },
    }
  })
  render() {
    if (!this.state.spin) {
      return (
        <div>
          <div id='titleTable'>
          <span>  احتياجات المعرض </span>
          </div>
          <div className='DataTableContiner'>
            <MuiThemeProvider
              theme={this.getMuiTheme()}>
              <MaterialDatatable data={this.state.Done} columns={columns} options={options} />
            </MuiThemeProvider>
          </div>
          <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
              />
        </div>
      )
    }
    else {
      return (
        <div style={{ width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spinner />
        </div>
      )
    }

  }
}
export default Table2;